<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>

        <h3 class="text-2xl my-4">Historial</h3>

        <a-card v-for="(value, attribute, key) in history" :key="key">
            <timeline>
                <timeline-title>
                    <h4 class="text-xl font-bold">{{ attribute.slice(0, 4) }}-{{ attribute.charAt(4) }}</h4>
                </timeline-title>
                <timeline-item
                    :bg-color="_value[0].specialization.color"
                    v-for="(_value, _attribute, _key) in value"
                    :key="`${key}_${_key}`">
                    
                    <h4 class="text-xl mb-2">{{ _attribute }}</h4>

                    <div class="rounded-2xl overflow-hidden shadow-md">
                        <a-table :source="_value" :columns="columns" bordered rounded />
                    </div>
                </timeline-item>
            </timeline>
        </a-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'
export default {
    components: {
        Timeline,
        TimelineItem,
        TimelineTitle
    },
    data: () => ({
        columns: [
            { title: 'Materia', key: 'course.title' },
            { title: 'Sección', key: 'section.letter_code' },
        ],
        loading: false,
        history: {}
    }),
    methods: mapActions({
        fetchTeacher: 'teachers/show'
    }),
    mounted() {

        const { id } = this.$route.params

        this.$repository.teachers
            .historic(this.$route.params.id)
            .then(({ data }) => {
                this.history = data
            })

        this.fetchTeacher({
            id,
            filters: {
                with: 'courseSections.semester,courseSections.section,courseSections.specialization,courseSections.course,courses'
            }
        })
    }
}
</script>

<style scoped>

</style>